<template>
    <transition name="dialog"
                appear>
        <div v-if="modalStore.modals.showMetamaskAuthModal"
             class="modal modal-transition"
             @click="handleModalClose">
            <div class="modal-wrapper">
                <div class="modal-content"
                     @click.stop>
                    <div class="text-left">
                        <span class="close-button"
                              @click="handleModalClose">&times;</span>
                    </div>
                    <div>
                        <i class="fa-solid fa-user-shield user-shield-icon" />
                        <div class="modal-title">
                            <translate translation-key="quests-dashboard.completeConnectingWithMetamask"
                                       fallback="Complete Connecting With Metamask" />
                        </div>
                        <div class="modal-subtitle">
                            <translate translation-key="quests-dashboard.clickMetamaskWallet"
                                       fallback="Click the button below to continue connecting with Metamask Wallet." />
                        </div>
                    </div>
                    <div>
                        <button class="button quiz-button--metamask"
                                @click="handleMetamaskConnect">
                            <span class="button-icon-wrapper">
                                <img :src="asset_cdn('assets/auth/metamask.svg')"
                                     alt="Metamask logo"
                                     title="Metamask logo">
                            </span>
                            <span>
                                <translate translation-key="quests-dashboard.completeConnection"
                                           fallback="Complete Connection" />
                            </span>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script setup>
    import { useModalStore } from '@/js/store/quests/game/modules/ModalStore';
    import { asset_cdn } from '@/js/helpers/helpers';
    import { connectToMetamask } from '@/js/auth/helpers/walletConnectHelpers';
    import { useWeb3Store } from '@/js/store/web3/Web3Store';
    import { deleteAuthTypeQueryParam } from '@/js/quests/global/helpers/queryParamHelpers';
    import { useModalActive } from '@/js/composables/useModalActive';

    const web3Store = useWeb3Store();
    const modalStore = useModalStore();

    const handleModalClose = () => {
        deleteAuthTypeQueryParam();
        modalStore.setMetamaskAuthModal(false);
    };

    const handleMetamaskConnect = () => {
        connectToMetamask(web3Store.otpCode);
        handleModalClose();
    };

    useModalActive();
</script>

<style scoped lang="scss">
@import "@/sass/design-system/variables";
@import "@/sass/partials/variables";
@import "@/js/quests/global/sass/mixins/_buttons";

.modal-transition {
    display: flex;
    align-items: center;
    justify-content: center;

    .modal-wrapper {
        max-width: 500px;
        max-height: 500px;
    }

    .modal-content {
        justify-content: space-between;
        padding: 48px;
        text-align: center;

        @media (min-width: 768px) {
            justify-content: flex-start;
        }

        @media (max-width: 650px) {
            padding: 8px 16px 16px;
        }
    }

    div {
        color: $baseWhite;
    }

    .modal-title {
        padding: 0 8px;
        margin-bottom: 8px;
        font-size: clamp(26px, 4vw, 38px);
        font-weight: 700;
    }

    .modal-subtitle {
        padding: 0 8px;
        margin-bottom: 16px;
        font-size: clamp(13px, 2vw, 14px);
    }

    .user-shield-icon {
        margin-bottom: 16px;
        color: $orange;
        font-size: 80px;
    }

    .button-icon-wrapper {
        display: flex;
        width: 30px;
        height: 30px;
        align-items: center;
        justify-content: center;
        padding: 4px;
        border-radius: 4px;
        background-color: $baseWhite;

        img {
            width: 17px;
        }
    }

    @media (max-width: 768px) {
        .modal-title {
            font-size: 29px;
        }

        .modal-subtitle {
            font-size: 14px;
        }
    }
}

.quiz-button--metamask {
    @include metamask-button;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    margin-bottom: 4px;
    gap: 16px;
}
</style>
