import { questsRoutes } from '@/js/constants/questsRoutes';
import { useAuthStore } from '@/js/store/auth/AuthStore';
import { useWeb3Store } from '@/js/store/web3/Web3Store';
import { Web3ProviderEnum } from '@/js/store/web3/types/enums';

export function shouldAddWalletAfterConnect(): boolean {
    const currentPath: string = window.location.pathname;

    return useAuthStore().user !== null || currentPath === questsRoutes.MISSION_ROUTE;
}

export async function connectToCoinbase(otp: string | null): Promise<void> {
    if (shouldAddWalletAfterConnect()) {
        await useWeb3Store().coinbaseConnect(otp, false, true);
    } else {
        await useWeb3Store().coinbaseConnect(otp, true, false);
    }
}

export async function connectToMetamask(otp: string | null, walletProvider: Web3ProviderEnum = Web3ProviderEnum.METAMASK): Promise<void> {
    if (shouldAddWalletAfterConnect()) {
        await useWeb3Store().metamaskConnect(walletProvider, otp, false, true);
    } else {
        await useWeb3Store().metamaskConnect(walletProvider, otp, true, false);
    }
}
